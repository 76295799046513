import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip, } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useEffect, useState } from "react";
import { CustomNumArgs } from "../../../../FormGenerator/FormFromManifest/CustomNumArgs";
export const SetActionOptions = ({ actionOptions, tableColumnHeaders, setActionParam, values, errors, }) => {
    return (_jsx(_Fragment, { children: actionOptions.map((e) => (_jsx(SetActionOption, { option: e, columns: tableColumnHeaders, setActionParam: setActionParam, defaultColumns: values[e.key] || [""], errors: errors === null || errors === void 0 ? void 0 : errors[e.key], optional: e.optional }, `set-action-option${e.key}`))) }));
};
function SetActionOption({ option, columns, setActionParam, defaultColumns, errors, optional, }) {
    const initialValues = defaultColumns && defaultColumns.length > 0 ? defaultColumns : [""];
    // const initialValues = defaultColumns || [""];
    const [optionValueArr, setOptionValueArr] = useState(initialValues);
    useEffect(() => {
        setOptionValueArr(initialValues);
    }, [JSON.stringify(initialValues)]);
    useEffect(() => {
        setActionParam(option.key, optionValueArr);
    }, [JSON.stringify(optionValueArr)]);
    const handleAddOption = () => {
        setOptionValueArr((prev) => [...prev, ""]);
    };
    const handleSetOption = (index, value) => {
        setOptionValueArr((prev) => {
            const newArr = [...prev];
            newArr[index] = value;
            return newArr;
        });
    };
    const handleRemoveLast = () => {
        setOptionValueArr((prev) => {
            const newArr = [...prev];
            newArr.pop();
            return newArr;
        });
    };
    const generateFormField = (i) => {
        return (_jsx(SetActionOptionEntry, { optionLabel: option.label, selOptions: columns, value: optionValueArr[i], handleSetOption: (value) => {
                handleSetOption(i, value);
            }, error: errors === null || errors === void 0 ? void 0 : errors[i], optional: optional }));
    };
    return (_jsxs(Box, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: 1 }, alignItems: "center" }, { children: [_jsx(Tooltip, Object.assign({ title: optional ? `[Optional] ${option.description}` : option.description, arrow: true, placement: "top" }, { children: _jsx(HelpOutlineIcon, { fontSize: "small", sx: { color: "#888a85", zIndex: 2 } }) })), option.multiple_values ? (_jsx(CustomNumArgs, { fields: optionValueArr, handleAdd: handleAddOption, handleRemove: handleRemoveLast, repeatElement: generateFormField, maxNumArgs: columns.length - 1, dense: true })) : (generateFormField(0))] })));
}
function SetActionOptionEntry({ optionLabel, selOptions, value, handleSetOption, error, optional, }) {
    return (_jsxs(FormControl, Object.assign({ fullWidth: true, size: "small", error: !!error }, { children: [_jsxs(InputLabel, Object.assign({ size: "small", id: "select-table-click-action-label", sx: { fontSize: "0.875rem" } }, { children: [optionLabel, " ", optional && " *"] })), _jsxs(Select, Object.assign({ value: value, onChange: (e) => handleSetOption(e.target.value), size: "small", label: optionLabel, sx: {
                    "& .MuiSelect-select": { fontSize: "0.875rem" },
                }, inputProps: {
                    style: {
                        fontSize: "0.875rem",
                    },
                }, MenuProps: {
                    PaperProps: {
                        sx: {
                            "& .MuiMenuItem-root": {
                                fontSize: "0.875rem",
                            },
                        },
                    },
                } }, { children: [_jsx(MenuItem, Object.assign({ dense: true, value: "", sx: { fontSize: "0.875rem" } }, { children: "" })), selOptions.map((option, i) => (_jsx(MenuItem, Object.assign({ dense: true, value: option, sx: { fontSize: "0.875rem" } }, { children: option }), `set-action-option-menu-item-${i}`)))] })), error && (_jsx(FormHelperText, Object.assign({ sx: { color: "error" } }, { children: error })))] })));
}
