import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
export function TooltipControlled(tooltipPops) {
    // Controlled so that the tooltip is not open when hovering its popup.
    const [open, setOpen] = useState(false);
    const handleTooltipOpen = () => {
        // Clear any existing close timer
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
            closeTimeoutRef.current = null;
        }
        setOpen(true);
    };
    const closeTimeoutRef = useRef(null);
    const handleTooltipClose = () => {
        // Add a small delay before closing to prevent flicker
        closeTimeoutRef.current = setTimeout(() => {
            setOpen(false);
            closeTimeoutRef.current = null;
        }, 100);
    };
    // Cleanup timeout on unmount
    useEffect(() => {
        return () => {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
            }
        };
    }, []);
    return (_jsx(Tooltip, Object.assign({}, tooltipPops, { open: open, title: tooltipPops.title, disableHoverListener: true }, { children: _jsx("span", Object.assign({ onMouseEnter: handleTooltipOpen, onMouseLeave: handleTooltipClose, onTouchStart: handleTooltipOpen, onTouchEnd: handleTooltipClose }, { children: tooltipPops.children })) })));
}
