var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { getCoreRowModel, useReactTable, getSortedRowModel, } from "@tanstack/react-table";
import { QueryClient, QueryClientProvider, } from "@tanstack/react-query";
import { NAPGenericStore } from "../GenericViewerState";
import { addNewColToOrder, downloadTable, getHiddenAndIgnoredColumns, } from "./utils";
import { dispatchConfirmationDialogEvent, } from "..";
import { calculateTableHeight, getComponentYDistance } from "./utils";
import { NAPPlotStore, NAPTableStore } from "../StateStores";
import { Box, CircularProgress, Grid, Table, TableContainer, Typography, } from "@mui/material";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { applyColFilter, applySorting } from "./fetchData";
import { TableInfo } from "./TableInfo";
import { TableNavigation } from "./TableNavigation/TableNavigation";
import { DownloadRowsSelection } from "./TableButtons/DownloadTableOptions";
import { getDefaultColumn } from "./DefaultColumn";
import { TableButtons } from "./TableButtons/TableButtons";
import { PMVTableHead } from "./TableHead";
import { PMVTableBody } from "./TableBody";
import { clearFetchedTableColumn, updateTableData, useInfiniteTableQuery, } from "./useInfiniteTableQuery";
import { ColumnOrderDialog } from "./ColumnOrder/ColumnOrderDialog";
import { shallow } from "zustand/shallow";
import { dispatchTextInputDialogEvent } from "../UserInputDialogs/TextInputDialog";
import { dispatchNotificationEvent } from "../NotificationSystem/utils";
function PMVTable({ table, rows, tableContainerRefDiv, isWaiting, onRowClick, onMouseEnter, onMouseLeave, svgColumns, rowsAreClickable, activeTableIdx, rowOnClickActions, localBasePath, DCpath, tableID, addTableColumn, setColumnIsEditable, clearTableColumn, filterOutColumn, copyColumn, navigationMode, toggleSMILESToImageColumn, }) {
    return (_jsxs(Table, Object.assign({ style: {
            display: "grid",
            borderCollapse: "collapse",
            borderSpacing: 0,
            tableLayout: "fixed",
        }, size: "small", stickyHeader: true }, { children: [_jsx(PMVTableHead, { table: table, addTableColumn: addTableColumn, setColumnIsEditable: setColumnIsEditable, clearTableColumn: clearTableColumn, filterOutColumn: filterOutColumn, copyColumn: copyColumn, toggleSMILESToImageColumn: toggleSMILESToImageColumn }), _jsx(PMVTableBody, { tableContainerRefDiv: tableContainerRefDiv, table: table, rows: rows, rowsAreClickable: rowsAreClickable, svgColumns: svgColumns, onRowClick: onRowClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, isWaiting: isWaiting, activeTableIdx: activeTableIdx, rowOnClickActions: rowOnClickActions, localBasePath: localBasePath, DCpath: DCpath, tableID: tableID, navigationMode: navigationMode })] })));
}
function InfiniteScrollTable({ tableRawData, onRowClick, onMouseEnter, onMouseLeave, rowsAreClickable, isWaiting, onDelete, addTableColumn, handleToggleNavigationMode, navigationMode, handleClickTableNavigation, activeTableIdx, setColumnIsEditable, clearTableColumn, renameTable, setTableColor, addOnRowClickAction, delOnRowClickAction, editOnRowClickAction, toggleSMILESToImageColumn, }) {
    var _a, _b, _c, _d, _e;
    const columns = tableRawData.columns;
    const tableID = tableRawData.tableID;
    const data = tableRawData.rows;
    const options = tableRawData.options;
    const svgColumns = tableRawData.svgColumns;
    const columnNames = columns.map((x) => x.header);
    // Hiden and ignored columns  --------------
    const { _hiddenColumns, _ignoredColumns } = getHiddenAndIgnoredColumns(options, columnNames);
    // Set the component max. size, necessary for the virtual table.
    const [plots] = NAPPlotStore((state) => [state.plots], shallow);
    const [tables] = NAPTableStore((state) => [state.tables], shallow);
    const [activePanels] = NAPGenericStore((state) => [state._activePanels], shallow);
    const consoleIsOpen = activePanels.console;
    const [yPosition, setYPosition] = useState(0);
    const tableContainerRef = useRef(null);
    const tableContainerRefCurrent = tableContainerRef.current;
    useEffect(() => {
        const newYPosition = getComponentYDistance(tableContainerRefCurrent);
        if (newYPosition !== yPosition)
            setYPosition(newYPosition);
    }, [tables.length, plots.length, tableContainerRefCurrent, tableID]); //Change to table index
    useEffect(() => {
        const handleResize = () => {
            const newYPosition = getComponentYDistance(tableContainerRefCurrent);
            setYPosition(newYPosition);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [tableContainerRefCurrent]);
    const height = useMemo(() => {
        return calculateTableHeight(yPosition, navigationMode, consoleIsOpen);
    }, [yPosition, navigationMode, consoleIsOpen]);
    const initHiddenColumns = [..._hiddenColumns, ..._ignoredColumns];
    const initHiddenColumnsDict = Object.fromEntries(columns.map((col) => [
        col.header,
        !initHiddenColumns.includes(col.header),
    ]));
    // Table state vars --------------
    const [sorting, setSorting] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState(initHiddenColumnsDict);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnOrder, setColumnOrder] = useState([]);
    const [columnOrderDialogIsOpen, setColumnOrderDialogIsOpen] = useState(false);
    useEffect(() => {
        if (sorting.length > 0)
            setSorting([]);
        if (columnFilters.length > 0)
            setColumnFilters([]);
        if (columnOrder.length > 0)
            setColumnOrder([]);
        setColumnVisibility(initHiddenColumnsDict);
    }, [tableID]);
    // Apply modifications to table data (sort and filter)
    const filteredData = useMemo(() => applyColFilter(columnFilters, data), [columnFilters, data]);
    const filteredSortedData = useMemo(() => applySorting(sorting, filteredData), [sorting, filteredData]);
    // Define data fetching --------------
    const fetchSize = 25;
    const { data: tableData, fetchNextPage, isFetching, isLoading, } = useInfiniteTableQuery({
        tableID,
        sorting,
        columnFilters,
        filteredSortedData,
        fetchSize,
    });
    const updateInfiniteTableData = (rowIndex, columnId, newValue) => {
        queryClient.setQueryData(["tableData", tableID, sorting, columnFilters], (oldData) => updateTableData(oldData, rowIndex, fetchSize, columnId, newValue));
    };
    const clearColumnFromInfinieTable = (columnId) => {
        queryClient.setQueryData(["tableData", tableID, sorting, columnFilters], (oldData) => clearFetchedTableColumn(oldData, columnId));
    };
    const flatData = useMemo(() => { var _a, _b; return (_b = (_a = tableData === null || tableData === void 0 ? void 0 : tableData.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [tableData]);
    const totalDBRowCount = (_d = (_c = (_b = (_a = tableData === null || tableData === void 0 ? void 0 : tableData.pages) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.totalRowCount) !== null && _d !== void 0 ? _d : 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {
        if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
            if (scrollHeight - scrollTop - clientHeight < 300 &&
                !isFetching &&
                totalFetched < totalDBRowCount) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, isFetching, totalFetched, totalDBRowCount]);
    // Create react data obj --------------
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);
    const defaultColumn = getDefaultColumn(tableContainerRef.current);
    const table = useReactTable({
        data: flatData,
        columns,
        defaultColumn,
        state: {
            sorting,
            columnVisibility,
            columnFilters,
            columnOrder,
        },
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        onColumnOrderChange: setColumnOrder,
        // getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        meta: {
            updateData: (currentRowIdx, originalRowIdx, columnId, value) => {
                // Skip page index reset until after next rerender
                NAPTableStore.getState().updateTableCell(activeTableIdx, originalRowIdx, columnId, value);
                // Update the Infinite Table data manually. Re-initializing it would cause jumping to the 1st row.
                updateInfiniteTableData(currentRowIdx, columnId, value);
            },
        },
    });
    const { rows } = table.getRowModel();
    // Define some functions
    const _handleClickTableNavigation = (rowIdx) => __awaiter(this, void 0, void 0, function* () {
        if (!handleClickTableNavigation)
            return;
        if (rowIdx >= totalFetched - 1 &&
            !isFetching &&
            totalFetched < totalDBRowCount) {
            NAPGenericStore.getState().setLoadingDCFile(true);
            yield fetchNextPage();
            NAPGenericStore.getState().setLoadingDCFile(false);
        }
        const row = rows[rowIdx];
        if (!row) {
            return;
        }
        yield handleClickTableNavigation(row);
        NAPGenericStore.getState().setLoadingDCFile(false);
    });
    const handleDownloadTable = (selection) => {
        let _data;
        if (selection == DownloadRowsSelection.all) {
            _data = data;
        }
        else {
            _data = filteredSortedData;
        }
        downloadTable(columns, _data, tableID);
    };
    const testColumnExists = (colName) => {
        if (columnNames.includes(colName)) {
            return { success: false, error: "This column name already exists." };
        }
        return { success: true };
    };
    const _addTableColumn = addTableColumn
        ? (refColId, addToRight) => __awaiter(this, void 0, void 0, function* () {
            const colName = yield dispatchTextInputDialogEvent({
                title: "Add column",
                text: "Column name",
                testCallback: testColumnExists,
            });
            if (colName) {
                addTableColumn(colName, refColId, addToRight);
                addNewColToOrder(table, refColId, colName, addToRight);
            }
        })
        : undefined;
    const _clearTableColumn = clearTableColumn
        ? (colId, isInteractive) => __awaiter(this, void 0, void 0, function* () {
            const ok = yield dispatchConfirmationDialogEvent({
                message: isInteractive
                    ? "Clearing this column will disable related interactive features. Are you sure you want to proceed?"
                    : "Are you sure you want to delete the data from this column?",
            });
            if (ok) {
                clearTableColumn(colId);
                clearColumnFromInfinieTable(colId);
            }
        })
        : undefined;
    const filterOutColumn = (column) => {
        column.toggleVisibility();
    };
    const copyColumn = (columnId) => {
        const _data = filteredSortedData;
        const columnData = _data.map((row) => `${row[columnId]}`).join("\n");
        navigator.clipboard.writeText(columnData);
        dispatchNotificationEvent({
            message: `${columnId} column copied!`,
            type: "info",
        });
    };
    return isLoading ? (_jsxs(Box, Object.assign({ sx: { width: "100%", ml: 1 }, display: "flex", gap: 1 }, { children: [_jsx(CircularProgress, { size: "20px" }), _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Loading..." }))] }))) : (_jsxs(_Fragment, { children: [_jsx(TableButtons, { table: table, ignoredColumns: _ignoredColumns, navigationMode: navigationMode, handleToggleNavigationMode: handleToggleNavigationMode, handleDownloadTable: handleDownloadTable, onDelete: onDelete, renameTable: renameTable, setTableColor: setTableColor, tableOptions: tableRawData.options, addOnRowClickAction: addOnRowClickAction, delOnRowClickAction: delOnRowClickAction, editOnRowClickAction: editOnRowClickAction, tableColumnHeaders: columnNames.filter((col) => col !== "_pmv_originalId"), reorderColumns: () => {
                    setColumnOrderDialogIsOpen(true);
                } }), _jsx(Grid, Object.assign({ item: true, xs: 12, sx: { cursor: isWaiting ? "wait" : "auto", order: 5 } }, { children: _jsxs(TableContainer, Object.assign({ ref: tableContainerRef, style: {
                        maxHeight: height,
                        overflow: "auto",
                        position: "relative", //needed for sticky header
                    }, onScroll: (e) => fetchMoreOnBottomReached(e.target) }, { children: [rowsAreClickable && (_jsx(TableInfo, { text: "Click on the table rows to hide/show data." })), _jsx(PMVTable, { table: table, rows: rows, tableContainerRefDiv: tableContainerRef.current, isWaiting: isWaiting, onRowClick: onRowClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, svgColumns: svgColumns, rowsAreClickable: rowsAreClickable, activeTableIdx: activeTableIdx, rowOnClickActions: (_e = tableRawData.options) === null || _e === void 0 ? void 0 : _e.onRowClick, localBasePath: tableRawData.localBasePath, DCpath: tableRawData.DCpath, tableID: tableID, addTableColumn: _addTableColumn, setColumnIsEditable: setColumnIsEditable, clearTableColumn: _clearTableColumn, filterOutColumn: filterOutColumn, copyColumn: copyColumn, navigationMode: navigationMode, toggleSMILESToImageColumn: toggleSMILESToImageColumn })] })) })), !!navigationMode && (_jsx(TableNavigation, { handleClickTableNavigation: _handleClickTableNavigation, rows: rows, sorting: sorting, columnFilters: columnFilters, rowsNotReady: isFetching || isLoading })), _jsx(ColumnOrderDialog, { open: columnOrderDialogIsOpen, onClose: () => {
                    setColumnOrderDialogIsOpen(false);
                }, table: table })] }));
}
const queryClient = new QueryClient();
export function DataTable(args) {
    return (_jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(InfiniteScrollTable, Object.assign({}, args)) })));
}
