var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { dispatchTextInputDialogEvent } from "../../../UserInputDialogs/TextInputDialog";
export function RenameTableMenuItem({ renameTable, handleClose, }) {
    const handleRenameTable = () => __awaiter(this, void 0, void 0, function* () {
        const name = yield dispatchTextInputDialogEvent({
            title: "Table name",
            text: "",
        });
        if (name) {
            renameTable(name);
        }
        handleClose();
    });
    return (_jsx(Tooltip, Object.assign({ title: "Update the table name, visible when more than one table is loaded.", placement: "right", arrow: true }, { children: _jsxs(MenuItem, Object.assign({ onClick: () => {
                handleRenameTable();
            } }, { children: [_jsx(ListItemIcon, { children: _jsx(EditIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Rename" })] })) })));
}
