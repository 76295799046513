import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Menu, MenuList } from "@mui/material";
import { RoundActionIconButton } from "../../../components/Buttons/ActionIconButton/RoundActionIconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RenameTableMenuItem } from "./RenameTableMenuItem";
import { AssignColorMenuItem } from "./AssignColorMenuItem";
import { ConfigTableInteractivity } from "./ConfigTableInteractivity/ConfigTableInteractivity";
import { ReorderColumnsMenuItem } from "./ReorderColumnsMenuItem";
export function MoreTableOptions({ renameTable, setTableColor, tableOptions, addOnRowClickAction, delOnRowClickAction, editOnRowClickAction, tableColumnHeaders, reorderColumns, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Download table", onClick: handleClick }, { children: _jsx(MoreVertIcon, { fontSize: "small" }) })), _jsx(Menu, Object.assign({ id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                } }, { children: _jsxs(MenuList, Object.assign({ dense: true }, { children: [renameTable ? (_jsx(RenameTableMenuItem, { renameTable: renameTable, handleClose: handleClose })) : (_jsx(_Fragment, {})), setTableColor ? (_jsx(AssignColorMenuItem, { setTableColor: setTableColor, handleCloseParent: handleClose, tableColor: tableOptions === null || tableOptions === void 0 ? void 0 : tableOptions.color })) : (_jsx(_Fragment, {})), addOnRowClickAction && tableColumnHeaders ? (_jsx(ConfigTableInteractivity, { handleClose: handleClose, addOnRowClickAction: addOnRowClickAction, tableColumnHeaders: tableColumnHeaders, tableClickOptions: tableOptions === null || tableOptions === void 0 ? void 0 : tableOptions.onRowClick, delOnRowClickAction: delOnRowClickAction, editOnRowClickAction: editOnRowClickAction })) : (_jsx(_Fragment, {})), reorderColumns ? (_jsx(ReorderColumnsMenuItem, { reorderColumns: reorderColumns, handleClose: handleClose })) : (_jsx(_Fragment, {}))] })) }))] }));
}
