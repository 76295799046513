import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { ListItemText } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Tooltip } from "@mui/material";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
export function ReorderColumnsMenuItem({ reorderColumns, handleClose, }) {
    return (_jsx(Tooltip, Object.assign({ title: "Reorder columns.", placement: "right", arrow: true }, { children: _jsxs(MenuItem, Object.assign({ onClick: () => {
                reorderColumns();
                handleClose();
            } }, { children: [_jsx(ListItemIcon, { children: _jsx(LowPriorityIcon, { fontSize: "small", sx: { transform: "rotate(90deg)" } }) }), _jsx(ListItemText, { children: "Reorder columns" })] })) })));
}
