var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createVolumeRepresentationParams } from "molstar/lib/mol-plugin-state/helpers/volume-representation-params";
import { StateTransforms } from "molstar/lib/mol-plugin-state/transforms";
import { Volume } from "molstar/lib/mol-model/volume";
/*
  Give the ref to the viewer as a parameter so that TS stops complaining
  about it being possibly undefined.
*/
export function renderVolume(data, molstar, volumeParams) {
    return new Promise((resolve, reject) => {
        molstar.dataTransaction(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const lines = data.split("\n");
            const header = extractHeader(lines);
            const headerNLines = header ? header.split("\n").length : undefined;
            const isoVals = getMinMaxIsoValues(lines, headerNLines);
            const defaultVal = isoVals.min + (isoVals.max - isoVals.min) / 2;
            const _data = yield molstar.builders.data.rawData({ data: data });
            const parsed = yield ((_a = molstar.dataFormats
                .get("cube")) === null || _a === void 0 ? void 0 : _a.parse(molstar, _data));
            const firstVolume = parsed.volume;
            if (!(firstVolume === null || firstVolume === void 0 ? void 0 : firstVolume.isOk)) {
                reject();
                return;
            }
            const repr = molstar.build();
            const volume = parsed.volume;
            const volumeData = volume.cell.obj.data;
            const defaultVolumeParams = {
                visuals: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.type)
                    ? volumeParams.type
                    : "wireframe" /* TS is so dumb sometimes.*/,
                alpha: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.alpha) ? volumeParams.alpha : 0.4,
                isoValue: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.currentIsoValue)
                    ? volumeParams.currentIsoValue
                    : defaultVal,
                color: (volumeParams === null || volumeParams === void 0 ? void 0 : volumeParams.color) ? volumeParams.color : 0xff6600,
            };
            const representation = repr.to(parsed.volume.ref).apply(StateTransforms.Representation.VolumeRepresentation3D, createVolumeRepresentationParams(molstar, firstVolume.data, {
                type: "isosurface",
                typeParams: {
                    visuals: [defaultVolumeParams.visuals],
                    alpha: defaultVolumeParams.alpha,
                    isoValue: Volume.adjustedIsoValue(volumeData, defaultVolumeParams.isoValue, "absolute"),
                },
                color: "uniform",
                colorParams: { value: defaultVolumeParams.color },
            })).selector;
            yield repr.commit();
            const _volume = {
                //ref: representation,
                volumeData: firstVolume.data,
                params: defaultVolumeParams,
                maxIsoVal: isoVals.max,
                minIsoVal: isoVals.min,
                visibility: true,
                header: header,
            };
            resolve([_volume, representation]);
        }));
    });
}
function getMinMaxIsoValues(lines, headerNLines = 7) {
    for (let i = 0; i < headerNLines; i++) {
        lines.shift();
    }
    const uniqueValues = new Set();
    lines.forEach((line) => {
        const numbers = line.split(/\s+/);
        numbers.shift();
        numbers.forEach((num) => {
            const pfloat = parseFloat(num);
            if (!Number.isNaN(pfloat))
                uniqueValues.add(pfloat);
        });
    });
    const sortedValues = Array.from(uniqueValues).sort((a, b) => a - b);
    return {
        min: sortedValues[0],
        max: sortedValues[sortedValues.length - 1],
    };
}
function extractHeader(lines) {
    try {
        if (lines.length < 6) {
            throw new Error("Invalid Cube file: Too few lines to contain a header.");
        }
        // Extract standard header lines
        const comment1 = lines[0];
        const comment2 = lines[1];
        const atomAndOriginLine = lines[2];
        // Parse the number of atoms from the third line
        const atomCount = parseInt(atomAndOriginLine.trim().split(/\s+/)[0], 10);
        // Extract grid dimensions and basis vectors
        const gridLines = lines.slice(3, 6);
        // Extract atomic data lines, if any
        const atomLines = atomCount > 0 ? lines.slice(6, 6 + atomCount) : [];
        // Combine all header lines
        const header = [
            comment1,
            comment2,
            atomAndOriginLine,
            ...gridLines,
            ...atomLines,
        ].join("\n");
        return header;
    }
    catch (error) {
        console.error(error);
        return;
    }
}
