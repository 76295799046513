import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Tooltip, Grid, IconButton, Divider } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
export function CustomNumArgs({ repeatElement, fields, handleAdd, handleRemove, dense, maxNumArgs, }) {
    const _maxNumArgs = maxNumArgs !== null && maxNumArgs !== void 0 ? maxNumArgs : 10;
    return (_jsxs(Grid, Object.assign({ container: true, direction: "row", alignItems: "flex-end", sx: { my: 1 } }, { children: [fields.length > 1 ? (_jsx(Divider, { sx: { mr: 1 }, orientation: "vertical", flexItem: true, variant: "middle" })) : null, _jsx(Grid, Object.assign({ item: true, container: true, direction: "column", xs: true, spacing: 2, wrap: "nowrap" }, { children: fields.map((field, i) => (_jsx(Grid, Object.assign({ item: true }, { children: repeatElement(i) }), field.id ? field.id : i))) })), fields.length > 1 ? (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { mb: dense ? 0.5 : undefined } }, { children: _jsx(Tooltip, Object.assign({ title: "Remove", arrow: true }, { children: _jsx(IconButton, Object.assign({ onClick: handleRemove, size: dense ? "small" : undefined }, { children: _jsx(DeleteOutlineIcon, { fontSize: dense ? "small" : undefined }) })) })) }))) : null, fields.length <= _maxNumArgs ? (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { mb: dense ? 0.5 : undefined } }, { children: _jsx(Tooltip, Object.assign({ title: "Add", arrow: true }, { children: _jsx(IconButton, Object.assign({ onClick: handleAdd, size: dense ? "small" : undefined }, { children: _jsx(AddCircleIcon, { fontSize: dense ? "small" : undefined }) })) })) }))) : null] })));
}
