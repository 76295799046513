import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Button, DialogContent } from "@mui/material";
import { InteractionsList } from "./InteractionsList";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
export function InteractivitySettings({ onClick, tableClickOptions, delOnRowClickAction, setEditOnRowClickAction, }) {
    return (_jsxs(DialogContent, { children: [_jsx(Box, { sx: { display: "flex", gap: 1 }, flexDirection: "column" }), _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1 }, flexDirection: "column" }, { children: [_jsx(Button, Object.assign({ variant: "contained", endIcon: _jsx(AddCircleOutlineIcon, {}), onClick: onClick }, { children: "Add" })), _jsx(InteractionsList, { tableClickOptions: tableClickOptions, delOnRowClickAction: delOnRowClickAction, setEditOnRowClickAction: setEditOnRowClickAction })] }))] }));
}
