import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Menu } from "@mui/material";
import { RoundActionIconButton } from "../../components/Buttons/ActionIconButton/RoundActionIconButton";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { TableColumnOptionsMenu } from "./TableColumnOptionsMenu";
function TableColumnOptionsButtonWrapper({ headerCellRef, children, disabled, active, }) {
    const [cellIsHovered, setCellIsHovered] = useState(false);
    useEffect(() => {
        const handleMouseEnter = () => setCellIsHovered(true);
        const handleMouseLeave = () => setCellIsHovered(false);
        const headerCell = headerCellRef.current;
        if (headerCell) {
            headerCell.addEventListener("mouseenter", handleMouseEnter);
            headerCell.addEventListener("mouseleave", handleMouseLeave);
        }
        // Cleanup event listeners on component unmount
        return () => {
            if (headerCell) {
                headerCell.removeEventListener("mouseenter", handleMouseEnter);
                headerCell.removeEventListener("mouseleave", handleMouseLeave);
            }
        };
    }, []);
    const isVisible = !disabled && (active || cellIsHovered);
    return (_jsxs(Box, Object.assign({ sx: {
            width: "100%",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
            visibility: isVisible ? "visible" : "hidden",
        } }, { children: [" ", children] })));
}
export function TableColumnOptionsButton({ header, headerCellRef, disabled, addTableColumn, setColumnIsEditable, clearTableColumn, filterOutColumn, copyColumn, toggleSMILESToImageColumn, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (_jsxs(_Fragment, { children: [_jsx(TableColumnOptionsButtonWrapper, Object.assign({ headerCellRef: headerCellRef, disabled: disabled, active: open }, { children: _jsx(RoundActionIconButton, Object.assign({ size: "small", onClick: onClick, disabled: disabled, active: open }, { children: _jsx(AddIcon, { fontSize: "small" }) })) })), _jsx(Menu, Object.assign({ anchorEl: anchorEl, open: open, onClose: handleClose }, { children: _jsx(TableColumnOptionsMenu, { handleClose: handleClose, header: header, addTableColumn: addTableColumn, setColumnIsEditable: setColumnIsEditable, clearTableColumn: clearTableColumn, filterOutColumn: filterOutColumn, copyColumn: copyColumn, toggleSMILESToImageColumn: toggleSMILESToImageColumn }) }))] }));
}
