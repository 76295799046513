// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
function getCubeDims(header) {
    const lines = header.split("\n");
    if (lines.length < 6) {
        throw new Error("Invalid Cube file: Too few lines to contain dimensions.");
    }
    // Parse dimensions from lines 4 to 6
    const dimX = parseInt(lines[3].trim().split(/\s+/)[0], 10);
    const dimY = parseInt(lines[4].trim().split(/\s+/)[0], 10);
    const dimZ = parseInt(lines[5].trim().split(/\s+/)[0], 10);
    return [dimX, dimY, dimZ];
}
export function reconstructCubeFile(header, data) {
    try {
        const [dimX, dimY, dimZ] = getCubeDims(header);
        // Validate data length
        if (data.length !== dimX * dimY * dimZ) {
            throw new Error("Data array length does not match the specified grid dimensions.");
        }
        const valuesPerLine = 6;
        const chunkSize = 10000; // Process data in chunks to avoid memory issues
        function* contentGenerator() {
            yield header + "\n";
            let line = "";
            for (let i = 0; i < data.length; i++) {
                line += data[i].toExponential(5);
                if ((i + 1) % valuesPerLine === 0) {
                    yield line + "\n";
                    line = "";
                }
                else if (i !== data.length - 1) {
                    line += " ";
                }
                // Yield accumulated chunk
                if (line.length > chunkSize) {
                    yield line;
                    line = "";
                }
            }
            // Add any remaining values
            if (line) {
                yield line;
            }
        }
        //@ts-ignore
        return new Blob(contentGenerator(), { type: "text/plain" });
    }
    catch (e) {
        console.error(e);
        return;
    }
}
