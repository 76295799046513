import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { MenuList, MenuItem, ListItemIcon, ListItemText, Divider, Tooltip, } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import ImageIcon from "@mui/icons-material/Image";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
function ListElement({ icon, text, onClick, tooltip_title, }) {
    const content = (_jsxs(MenuItem, Object.assign({ onClick: onClick }, { children: [_jsx(ListItemIcon, { children: icon }), _jsx(ListItemText, { primary: text })] })));
    //   <Tooltip
    //   title="Configure actions triggered by table clicks."
    //   placement="right"
    //   arrow
    // >
    return tooltip_title ? (_jsx(Tooltip, Object.assign({ title: tooltip_title, placement: "right", arrow: true }, { children: content }))) : (content);
}
export function TableColumnOptionsMenu({ header, handleClose, addTableColumn, setColumnIsEditable, clearTableColumn, filterOutColumn, copyColumn, toggleSMILESToImageColumn, }) {
    const menuItems = [];
    if (copyColumn) {
        menuItems.push(_jsx(ListElement, { icon: _jsx(ContentCopyIcon, { fontSize: "small" }), text: "Copy content", onClick: () => {
                copyColumn(header.id);
                handleClose();
            } }, "table-column-options-copy"));
    }
    if (filterOutColumn) {
        menuItems.push(_jsx(ListElement, { icon: _jsx(FilterAltIcon, { fontSize: "small" }), text: "Hide column", onClick: () => {
                filterOutColumn(header.column);
                handleClose();
            } }, "table-column-options-hide"));
    }
    if (clearTableColumn) {
        menuItems.push(_jsx(ListElement, { icon: _jsx(ClearIcon, { fontSize: "small" }), text: "Clear column", onClick: () => {
                const isInteractive = header.column.columnDef["isInteractive"];
                clearTableColumn(header.id, isInteractive);
                handleClose();
            } }, "table-column-options-clear"));
    }
    if (toggleSMILESToImageColumn) {
        const isSVG = header.column.columnDef["isSVG"];
        const isEditable = header.column.columnDef["isComment"];
        menuItems.push(_jsx(ListElement, { icon: isSVG ? (_jsx(ImageNotSupportedIcon, { fontSize: "small" })) : (_jsx(ImageIcon, { fontSize: "small" })), text: isSVG ? "Show SMILES as text" : "Convert SMILES to image", tooltip_title: isSVG
                ? undefined
                : "If the column contains SMILES, convert them to 2D molecule depiction.", onClick: () => {
                toggleSMILESToImageColumn(header.id);
                if (isEditable && setColumnIsEditable) {
                    setColumnIsEditable(header.id, !isEditable, false);
                }
                handleClose();
            } }, "table-column-options-toggle-smiles-to-image"));
    }
    if (setColumnIsEditable) {
        const isEditable = header.column.columnDef["isComment"];
        const isInteractive = header.column.columnDef["isInteractive"];
        menuItems.push(_jsx(Divider, {}, "table-column-options-divider0"), _jsx(ListElement, { icon: isEditable ? (_jsx(EditOffIcon, { fontSize: "small" })) : (_jsx(EditIcon, { fontSize: "small" })), text: isEditable ? "Stop editing column" : "Edit column", onClick: () => {
                setColumnIsEditable(header.id, !isEditable, isInteractive);
                handleClose();
            } }, "table-column-options-edit"));
    }
    if (addTableColumn) {
        menuItems.push(_jsx(Divider, {}, "table-column-options-divider1"), _jsx(ListElement, { icon: _jsx(AddIcon, { fontSize: "small" }), text: "Insert a column left", onClick: () => {
                addTableColumn(header.id);
                handleClose();
            } }, "table-column-options-insertleft"), _jsx(ListElement, { icon: _jsx(AddIcon, { fontSize: "small" }), text: "Insert a column right", onClick: () => {
                addTableColumn(header.id, true);
                handleClose();
            } }, "table-column-options-insertright"));
    }
    return _jsx(MenuList, Object.assign({ dense: true }, { children: menuItems }));
}
