import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import "../static/css/LandingPage.css";
import { Box, Button } from "@mui/material";
import { LandingPageNavigation } from "./LandingPageNavigation";
import { LandingPageBackground } from "./LandingPageBackground";
import { LandingPageContact } from "./LandingPageContact";
import { LandingPageFooter } from "./LandingPageFooter";
import { useLocation } from "wouter";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
function LandingPageBody() {
    const [, setLocation] = useLocation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ id: "landingpage_intro", className: "intro_v2", style: {
                    height: "80%",
                    minHeight: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                } }, { children: [_jsxs("h1", { children: ["PlayMolecule", _jsx("span", { children: "TM" })] }), _jsx("p", { children: "Molecular discovery" }), _jsx(Box, Object.assign({ sx: { display: "flex", justifyContent: "center", my: 5 } }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => setLocation("/"), endIcon: _jsx(KeyboardArrowRightIcon, {}) }, { children: "Get Started" })) }))] })), _jsx(Box, { children: _jsx(LandingPageContact, {}) })] }));
}
export function LandingPage() {
    return (_jsxs(_Fragment, { children: [_jsx(LandingPageBackground, {}), _jsxs(Box, Object.assign({ sx: {
                    zIndex: 1,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                } }, { children: [_jsx(LandingPageNavigation, {}), _jsx(LandingPageBody, {}), _jsx(LandingPageFooter, {})] }))] }));
}
