import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Dialog, DialogTitle, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip, } from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { InteractivitySettings } from "./InteractivitySettings";
import { AddInteraction } from "./AddInteraction";
import { EditInteraction, EditInteractionError } from "./EditInteraction";
export const ConfigTableInteractivity = ({ handleClose, addOnRowClickAction, tableColumnHeaders, tableClickOptions, delOnRowClickAction, editOnRowClickAction, }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseAll = () => {
        setOpenDialog(false);
        handleClose();
    };
    const _addOnRowClickAction = (action) => {
        addOnRowClickAction(action);
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Configure actions triggered by table clicks.", placement: "right", arrow: true }, { children: _jsxs(MenuItem, Object.assign({ onClick: () => setOpenDialog(true) }, { children: [_jsx(ListItemIcon, { children: _jsx(AdsClickIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Interactivity" })] })) })), _jsx(InteractivityDialog, { open: openDialog, handleClose: handleCloseAll, addOnRowClickAction: _addOnRowClickAction, tableColumnHeaders: tableColumnHeaders, tableClickOptions: tableClickOptions, delOnRowClickAction: delOnRowClickAction, editOnRowClickAction: editOnRowClickAction })] }));
};
const InteractivityDialog = ({ open, handleClose, addOnRowClickAction, tableColumnHeaders, tableClickOptions, delOnRowClickAction, editOnRowClickAction, }) => {
    const [mode, setMode] = useState("home");
    const [editedIntIdx, setEditedIntIdx] = useState(null);
    const handleGoToAddInteraction = () => {
        setMode("add");
    };
    const handleGoToInteractvityHome = () => {
        setMode("home");
    };
    const handleGoToEditInteraction = editOnRowClickAction
        ? (idx) => {
            setMode("edit");
            setEditedIntIdx(idx);
        }
        : undefined;
    const _editOnRowClickAction = (action) => {
        if (editedIntIdx !== null && editOnRowClickAction) {
            editOnRowClickAction(editedIntIdx, action);
            setMode("home");
        }
    };
    let title = "Interactivity";
    let content = (_jsx(InteractivitySettings, { onClick: handleGoToAddInteraction, tableClickOptions: tableClickOptions, delOnRowClickAction: delOnRowClickAction, setEditOnRowClickAction: handleGoToEditInteraction }));
    if (mode === "add") {
        title = "Add Interaction";
        content = (_jsx(AddInteraction, { cancelAddInteraction: handleGoToInteractvityHome, addOnRowClickAction: addOnRowClickAction, tableColumnHeaders: tableColumnHeaders }));
    }
    else if (mode === "edit" && editedIntIdx !== null) {
        title = "Edit Interaction";
        content =
            editedIntIdx !== null &&
                tableClickOptions &&
                editedIntIdx < tableClickOptions.length ? (_jsx(EditInteraction, { cancelEditInteraction: handleGoToInteractvityHome, acceptEditInteraction: _editOnRowClickAction, tableColumnHeaders: tableColumnHeaders, interactionSettings: tableClickOptions[editedIntIdx] })) : (_jsx(EditInteractionError, { cancelEditInteraction: handleGoToInteractvityHome }));
    }
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose }, { children: [_jsxs(DialogTitle, { children: [title, _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: handleClose, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] }), content] })));
};
