var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { ControlledAccordion } from "../../../../3dViewer/Controls/ControlledAccordion";
import { Box, Typography } from "@mui/material";
import { dispatchConfirmationDialogEvent, TableClickAction, } from "../../../../utils";
import { actionOption_toggleVisibility, actionOptions } from "./actionOptions";
import { RoundActionIconButton } from "../../../../components/Buttons/ActionIconButton/RoundActionIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
export function InteractionsList({ tableClickOptions, delOnRowClickAction, setEditOnRowClickAction, }) {
    const activeInteractions = tableClickOptions ? tableClickOptions : [];
    const [isOpen, setIsOpen] = useState(true);
    return (_jsx(ControlledAccordion, Object.assign({ name: "Active Interactions", hoverBackground: true, 
        // variant="outlined"
        open: isOpen, callback: () => setIsOpen((prev) => !prev) }, { children: activeInteractions.length > 0 ? (_jsx(Box, Object.assign({ sx: {
                overflowY: "auto",
                maxHeight: "10rem",
                display: "flex",
                flexDirection: "column",
                gap: 1,
            } }, { children: activeInteractions.map((interaction, i) => (_jsx(InteractionsListElement, { interaction: interaction, delOnRowClickAction: delOnRowClickAction ? () => delOnRowClickAction(i) : undefined, setEditOnRowClickAction: setEditOnRowClickAction
                    ? () => setEditOnRowClickAction(i)
                    : undefined }, `interaction-${interaction.action}-${i}`))) }))) : (_jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: "No interactions available." }))) })));
}
function InteractionsListElement({ interaction, delOnRowClickAction, setEditOnRowClickAction, }) {
    var _a;
    let actionUIParams;
    if (interaction.action === TableClickAction.toggleVisibility) {
        actionUIParams = actionOption_toggleVisibility;
    }
    else {
        actionUIParams = actionOptions.find((e) => e.key == interaction.action);
    }
    const _delOnRowClickAction = delOnRowClickAction
        ? () => __awaiter(this, void 0, void 0, function* () {
            const ok = yield dispatchConfirmationDialogEvent({
                message: "Are you sure you want to delete this interaction?",
            });
            if (ok)
                delOnRowClickAction();
        })
        : undefined;
    return actionUIParams ? (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            flexDirection: "row",
            gap: 1,
            justifyContent: "space-between",
            alignItems: "flex-start",
        } }, { children: [_jsxs(Box, Object.assign({ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ fontSize: "0.95rem" }, { children: actionUIParams.label })), (_a = actionUIParams.options) === null || _a === void 0 ? void 0 : _a.map((option, i) => (_jsx(InteractionsListElementParams, { option: option, interaction: interaction }, `interaction-element-option-${option.key}-${i} `)))] })), _jsxs(Box, { children: [setEditOnRowClickAction ? (_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Edit", onClick: setEditOnRowClickAction }, { children: _jsx(EditIcon, { sx: { fontSize: "18px" } }) }))) : (_jsx(_Fragment, {})), _delOnRowClickAction ? (_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Delete", onClick: _delOnRowClickAction, paletteCategory: "error", colorOnHover: true }, { children: _jsx(DeleteIcon, { sx: { fontSize: "18px" } }) }))) : (_jsx(_Fragment, {}))] })] }))) : (_jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: interaction.action })));
}
function InteractionsListElementParams({ option, interaction, }) {
    var _a;
    let value = [];
    if (option.key === "column") {
        value = interaction.column;
    }
    else {
        const _value = (_a = interaction.options) === null || _a === void 0 ? void 0 : _a[option.key];
        if (typeof _value === "string") {
            value = [_value];
        }
        else {
            value = _value;
        }
    }
    return (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", gap: 1 }, { children: [_jsxs(Typography, Object.assign({ display: "inline", variant: "body2", color: "text.secondary" }, { children: [option.label, ":"] })), _jsx(Typography, Object.assign({ display: "inline", variant: "body2", color: "text.secondary" }, { children: value.join(", ") }))] })));
}
