var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Button, DialogActions, DialogContent, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Tooltip, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { SetActionOptions } from "./SetActionOptions";
import { actionOptions } from "./actionOptions";
export function AddInteraction({ cancelAddInteraction, addOnRowClickAction, tableColumnHeaders, }) {
    var _a;
    const [activeActionKey, setActiveAction] = useState(actionOptions[0].key);
    const activeAction = actionOptions.find((e) => e.key == activeActionKey);
    const initialValues = activeAction === null || activeAction === void 0 ? void 0 : activeAction.options.reduce((acc, option) => (Object.assign(Object.assign({}, acc), { [option.key]: [] })), {});
    const [actionOptionValues, setActionOptionValues] = useState(initialValues || {});
    const [actionOptionErrors, setActionOptionErrors] = useState({});
    useEffect(() => {
        setActionOptionValues(initialValues || {});
        setActionOptionErrors({});
    }, [activeActionKey]);
    const setActionParam = (key, value) => {
        setActionOptionValues((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
        setActionOptionErrors({});
    };
    const handleAccept = () => {
        if (!mandatoryValuesAreSet || Object.keys(actionOptionErrors).length > 0)
            return;
        const errors = {};
        for (const valueKey in actionOptionValues) {
            const actionDetails = activeAction === null || activeAction === void 0 ? void 0 : activeAction.options.find((e) => e.key == valueKey);
            if (!actionDetails || actionDetails.optional)
                continue;
            actionOptionValues[valueKey].forEach((value, index) => {
                if (value == "") {
                    if (!(valueKey in errors)) {
                        errors[valueKey] = {};
                    }
                    errors[valueKey][index] = "This field is required.";
                }
            });
        }
        if (Object.keys(errors).length > 0) {
            setActionOptionErrors(errors);
            return;
        }
        const { column: columnValue } = actionOptionValues, otherValues = __rest(actionOptionValues, ["column"]);
        addOnRowClickAction({
            action: activeActionKey,
            column: columnValue,
            options: otherValues,
        });
    };
    const mandatoryValuesAreSet = activeAction === null || activeAction === void 0 ? void 0 : activeAction.options.filter((option) => !option.optional).map((option) => actionOptionValues[option.key]).every((value) => {
        return value != null && String(value).replace(",", "").trim().length > 0;
    });
    return (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", flexDirection: "column", gap: 1 } }, { children: [_jsx(ActionsSelect, { actionOptions: actionOptions, activeActionKey: activeActionKey, setActiveAction: setActiveAction }), _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: (_a = actionOptions.find((e) => e.key == activeActionKey)) === null || _a === void 0 ? void 0 : _a.description })), activeAction ? (_jsx(SetActionOptions, { actionOptions: activeAction === null || activeAction === void 0 ? void 0 : activeAction.options, tableColumnHeaders: tableColumnHeaders, setActionParam: setActionParam, values: actionOptionValues, errors: actionOptionErrors })) : (_jsx(_Fragment, {}))] })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: cancelAddInteraction }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: "contained", onClick: handleAccept, disabled: !mandatoryValuesAreSet || Object.keys(actionOptionErrors).length > 0 }, { children: "Confirm" }))] })] }));
}
function ActionsSelect({ actionOptions, activeActionKey, setActiveAction, }) {
    const actionOptionsDict = actionOptions.reduce((acc, action) => {
        acc[action.key] = action;
        return acc;
    }, {});
    const handleChange = (event) => {
        setActiveAction(event.target.value);
    };
    return (_jsxs(FormControl, Object.assign({ fullWidth: true, size: "small", sx: { mt: 1 } }, { children: [_jsx(InputLabel, Object.assign({ size: "small", id: "select-table-click-action-label" }, { children: "Action" })), _jsx(Select, Object.assign({ size: "small", labelId: "select-table-click-action-label", id: "select-table-click-action", value: activeActionKey, label: "Action", onChange: handleChange, renderValue: (val) => actionOptionsDict[val].label, sx: {
                    "& .MuiSelect-select": { fontSize: "0.875rem" },
                } }, { children: actionOptions.map((action) => (_jsxs(MenuItem, Object.assign({ value: action.key }, { children: [_jsx(ListItemText, Object.assign({ sx: { "& .MuiTypography-root": { fontSize: "0.875rem" } } }, { children: action.label })), _jsx(ListItemIcon, Object.assign({ sx: { pl: 1 } }, { children: _jsx(Tooltip, Object.assign({ title: actionOptionsDict[action.key].description, arrow: true, placement: "right" }, { children: _jsx(HelpOutlineIcon, { fontSize: "small" }) })) }))] }), `action-select-menu-ittem-${action.key}`))) }))] })));
}
