var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Button, DialogActions, DialogContent, Typography, } from "@mui/material";
import { TableClickAction, } from "../../../../utils/Interfaces/Table";
import { useState } from "react";
import { actionOptions } from "./actionOptions";
import { SetActionOptions } from "./SetActionOptions";
export function EditInteraction({ cancelEditInteraction, acceptEditInteraction, tableColumnHeaders, interactionSettings, }) {
    let activeActionId = interactionSettings.action;
    if (activeActionId === TableClickAction.toggleVisibility) {
        activeActionId = TableClickAction.loadFile;
    }
    const activeAction = actionOptions.find((e) => e.key == activeActionId);
    const initialValues = activeAction === null || activeAction === void 0 ? void 0 : activeAction.options.reduce((acc, option) => {
        var _a;
        let val;
        if (option.key === "column") {
            val = interactionSettings.column;
        }
        else {
            val = (_a = interactionSettings.options) === null || _a === void 0 ? void 0 : _a[option.key];
        }
        return Object.assign(Object.assign({}, acc), { [option.key]: val || [] });
    }, {});
    const [actionOptionValues, setActionOptionValues] = useState(initialValues || {});
    const [actionOptionErrors, setActionOptionErrors] = useState({});
    const setActionParam = (key, value) => {
        setActionOptionValues((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
        setActionOptionErrors({});
    };
    const handleAccept = () => {
        if (!mandatoryValuesAreSet || Object.keys(actionOptionErrors).length > 0)
            return;
        const errors = {};
        for (const valueKey in actionOptionValues) {
            const actionDetails = activeAction === null || activeAction === void 0 ? void 0 : activeAction.options.find((e) => e.key == valueKey);
            if (!actionDetails || actionDetails.optional)
                continue;
            actionOptionValues[valueKey].forEach((value, index) => {
                if (value == "") {
                    if (!(valueKey in errors)) {
                        errors[valueKey] = {};
                    }
                    errors[valueKey][index] = "This field is required.";
                }
            });
        }
        if (Object.keys(errors).length > 0) {
            setActionOptionErrors(errors);
            return;
        }
        const { column: columnValue } = actionOptionValues, otherValues = __rest(actionOptionValues, ["column"]);
        acceptEditInteraction({
            action: interactionSettings.action,
            column: columnValue,
            options: otherValues,
        });
    };
    const mandatoryValuesAreSet = activeAction === null || activeAction === void 0 ? void 0 : activeAction.options.filter((option) => !option.optional).map((option) => actionOptionValues[option.key]).every((value) => {
        return value != null && String(value).replace(",", "").trim().length > 0;
    });
    return (_jsxs(_Fragment, { children: [_jsxs(DialogContent, { children: [_jsxs(Box, Object.assign({ sx: { mb: 2 } }, { children: [_jsxs(Typography, { children: [" ", activeAction === null || activeAction === void 0 ? void 0 : activeAction.label, " "] }), _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: activeAction === null || activeAction === void 0 ? void 0 : activeAction.description }))] })), activeAction ? (_jsx(SetActionOptions, { actionOptions: activeAction === null || activeAction === void 0 ? void 0 : activeAction.options, tableColumnHeaders: tableColumnHeaders, setActionParam: setActionParam, values: actionOptionValues, errors: actionOptionErrors })) : (_jsx(_Fragment, {}))] }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: cancelEditInteraction }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: "contained", onClick: handleAccept, disabled: !mandatoryValuesAreSet || Object.keys(actionOptionErrors).length > 0 }, { children: "Confirm" }))] })] }));
}
export function EditInteractionError({ cancelEditInteraction, }) {
    return (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: _jsx(Typography, Object.assign({ variant: "body2", color: "text.secondary" }, { children: "Error editing interaction." })) }), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ variant: "outlined", onClick: cancelEditInteraction }, { children: "Back" })) })] }));
}
