import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { prettifyLabel } from "./utils";
import { Grid, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DOMPurify from "isomorphic-dompurify";
import { Filter } from "./TableButtons/RowFilters";
import { TableColumnOptionsButton } from "./TableColumnOptions/TableColumnOptionsButton";
import { useRef } from "react";
function TableHeadCellTitle({ header, }) {
    return (_jsx("div", { style: { overflow: "hidden", textOverflow: "ellipsis" }, dangerouslySetInnerHTML: {
            __html: DOMPurify.sanitize(prettifyLabel(header.column.columnDef.header)),
        } }));
}
function TableHeadCellArrows({ header, }) {
    var _a;
    return ((_a = {
        asc: (_jsx(KeyboardArrowUpIcon, { sx: {
                fontSize: 20,
            } })),
        desc: (_jsx(KeyboardArrowDownIcon, { sx: {
                fontSize: 20,
            } })),
    }[header.column.getIsSorted()]) !== null && _a !== void 0 ? _a : null);
}
function TableHeadCell({ header, addTableColumn, setColumnIsEditable, clearTableColumn, filterOutColumn, copyColumn, toggleSMILESToImageColumn, }) {
    const headerCellRef = useRef(null);
    const colProps = header.column.columnDef;
    const notClickable = colProps["configColumn"];
    const isSVG = colProps["isSVG"];
    const notEditable = isSVG;
    return (_jsx(TableCell, Object.assign({ colSpan: header.colSpan, style: {
            display: "flex",
            width: header.getSize(),
        } }, { children: header.isPlaceholder ? null : (_jsxs(Grid, Object.assign({ ref: headerCellRef, container: true, alignItems: "center", wrap: "nowrap", direction: "column", justifyContent: "space-between" }, { children: [!notClickable && (_jsx(Grid, Object.assign({ item: true, sx: { width: "100%" } }, { children: _jsx(TableColumnOptionsButton, { headerCellRef: headerCellRef, addTableColumn: addTableColumn, header: header, setColumnIsEditable: notEditable ? undefined : setColumnIsEditable, clearTableColumn: clearTableColumn, filterOutColumn: filterOutColumn, copyColumn: copyColumn, toggleSMILESToImageColumn: toggleSMILESToImageColumn }) }))), _jsxs(Grid, Object.assign({ container: true, item: true, xs: true, direction: "row", alignItems: "center", wrap: "nowrap", style: {
                        cursor: !notClickable && header.column.getCanSort()
                            ? "pointer"
                            : "auto",
                    }, onClick: notClickable ? undefined : header.column.getToggleSortingHandler(), spacing: 1 }, { children: [_jsx(Grid, Object.assign({ xs: true, item: true, sx: { fontWeight: "bold", overflow: "hidden" } }, { children: _jsx(TableHeadCellTitle, { header: header }) })), _jsx(Grid, Object.assign({ xs: "auto", item: true }, { children: _jsx(TableHeadCellArrows, { header: header }) }))] })), _jsx(Grid, Object.assign({ xs: "auto", item: true }, { children: _jsx(Filter, { column: header.column }) }))] }))) }), header.id));
}
export function PMVTableHead({ table, addTableColumn, setColumnIsEditable, clearTableColumn, filterOutColumn, copyColumn, toggleSMILESToImageColumn, }) {
    return (_jsx(TableHead, Object.assign({ style: {
            display: "grid",
            position: "sticky",
            top: 0,
            zIndex: 1,
        } }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, Object.assign({ style: { display: "flex", width: "100%" } }, { children: _jsxs(_Fragment, { children: [_jsx(TableCell, {}), headerGroup.headers.map((header) => {
                        return (_jsx(TableHeadCell, { header: header, addTableColumn: addTableColumn, setColumnIsEditable: setColumnIsEditable, clearTableColumn: clearTableColumn, filterOutColumn: filterOutColumn, copyColumn: copyColumn, toggleSMILESToImageColumn: toggleSMILESToImageColumn }, `table-head-cell-${header.id}`));
                    })] }) }), headerGroup.id))) })));
}
