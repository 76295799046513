// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { TableClickAction } from "../../../../utils";
import { TableActionOptionType } from "./interfaces";
export const actionOptions = [
    {
        key: TableClickAction.loadFile,
        label: "Toggle System Visibility",
        description: "On row click, toggle the visibility of a sistem.",
        options: [
            {
                key: "column",
                label: "Path Column",
                type: TableActionOptionType.tableColumn,
                description: "Column(s) that contains the system path, used to identify the associated system.",
                multiple_values: true,
            },
        ],
    },
    {
        key: TableClickAction.focusCoords,
        label: "Focus on Coordinates",
        description: "On row click, focus the camera on the coordinates specified on that row.",
        options: [
            {
                key: "column",
                label: "Coordinates Column",
                type: TableActionOptionType.tableColumn,
                description: "Column that contains the coordinates to focus on, in the format [x, y, z].",
            },
        ],
    },
    {
        key: TableClickAction.showSystemEntry,
        label: "Toggle Entry Visibility",
        description: "On row click, toggle the visibility of a system entry (e.g. SDF entry) based on its index.",
        options: [
            {
                key: "column",
                label: "Path Column",
                type: TableActionOptionType.tableColumn,
                description: "Column(s) that contains the system path, used to identify the associated system.",
                multiple_values: true,
            },
            {
                key: "showIndexColumn",
                label: "Index Column",
                type: TableActionOptionType.tableColumn,
                description: "Column(s) that contains the entry index, 1-based. Add one for each 'Path Column' (i.e. one for each system).",
                multiple_values: true,
            },
        ],
    },
    {
        key: TableClickAction.highlightSystem,
        label: "Highlight System",
        description: "On row click, highlight the system or a selection of system atoms.",
        options: [
            {
                key: "column",
                label: "Path Column",
                type: TableActionOptionType.tableColumn,
                description: "Column(s) that contains the system path, used to identify the associated system.",
                multiple_values: true,
            },
            {
                key: "selectionColumn",
                label: "Atom Selection Column",
                optional: true,
                type: TableActionOptionType.tableColumn,
                description: "Column(s) that contains the atom selection, in the format [atomIndex1, atomIndex2, ...]. For each 'Path Column' (i.e. one for each system), add one or leave empty to highlight the entire system.",
                multiple_values: true,
            },
        ],
    },
];
// We support toggleVisibility to maintain compatibility with old configurations, but don't want to provide it as an option on the UI
export const actionOption_toggleVisibility = actionOptions.find((e) => e.key == TableClickAction.loadFile);
