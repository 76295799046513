import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export function SystemDetailsDialog({ open, handleClose, system, }) {
    const systfiles = system.files;
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, fullWidth: true }, { children: [_jsxs(DialogTitle, { children: ["System Details", _jsx(IconButton, Object.assign({ "aria-label": "close", onClick: handleClose, sx: {
                            position: "absolute",
                            right: 4,
                            top: 4,
                        } }, { children: _jsx(CloseIcon, {}) }))] }), _jsx(DialogContent, Object.assign({ sx: { mx: 1 } }, { children: _jsxs(Box, Object.assign({ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column", gap: 1 }, { children: [_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", gap: 1 }, { children: [_jsx(Typography, Object.assign({ display: "inline", color: "text.secondary" }, { children: "System Name:" })), _jsx(Typography, Object.assign({ display: "inline" }, { children: system.name }))] })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", gap: 1 }, { children: [_jsx(Typography, Object.assign({ display: "inline", color: "text.secondary" }, { children: `File${systfiles && systfiles.length > 1 ? "s" : ""}:` })), systfiles ? (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }, { children: systfiles.map((file) => (_jsx(Typography, Object.assign({ display: "inline" }, { children: file }), file))) }))) : (_jsx(Typography, Object.assign({ display: "inline" }, { children: "-" })))] }))] })) }))] })));
}
